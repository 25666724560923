import { Link } from '@remix-run/react'
import { Icon } from '#app/components/ui/icon.js'

export default function MarketingFooter() {
  return (
    <footer className="bg-[#0f151a] py-7" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="container mt-4 flex flex-col justify-between md:flex-row">
        <LeftRail />
        <RightRail />
      </div>
    </footer>
  )
}

function LeftRail() {
  return (
    <div className="flex w-full flex-col items-center md:items-start justify-around gap-2 sm:flex-row md:flex-col sm:gap-4 lg:block lg:w-auto">
      <div className="flex w-full justify-around sm:block sm:w-auto">
        <img
          src="/img/logo-white.svg"
          className="h-16 w-auto"
          alt="Proven Valor logo"
        />
      </div>
      <div className="mt-6 sm:mt-4 text-center text-sm text-white sm:text-right md:text-left lg:justify-start">
        <div>© 2024 Proven Valor LLC.</div>
        <div>4425 E Agave Rd Bldg 2 Suite 106</div>
        <div>Phoenix, AZ 85044</div>
        <div className="mt-6 sm:mt-4">
          <Link to="mailto:support@provenvalor.com" className="flex items-center justify-center sm:justify-end md:justify-start">
            <Icon name="email" size="md" title="support@provenvalor.com" className="background-blue" />
            <span className="ml-1">Contact Us</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

function RightRail() {
  return (
    <div className="flex w-full flex-wrap items-center justify-center gap-8 lg:w-auto">
      <div className="flex flex-1 flex-col items-center justify-center text-white md:items-end">
        <div className="text-md flex flex-wrap">
          <ul className="mt-5 grid grid-cols-3-0-auto sm:grid-cols-4-0-auto gap-3">
            <li>
              <Link to="/#what-you-get">What You get</Link>
            </li>
            <li>
              <Link to="/#pricing">Pricing</Link>
            </li>
            <li className="">
              <Link
                to="/login"
              >
                Login
              </Link>
            </li>
          </ul>
        </div>
        <div className="mt-6 sm:mt-4 flex flex-col sm:flex-row gap-2 items-center justify-center text-center text-sm lg:justify-end">
          Follow Us
          <div className="flex justify-center sm:justify-start	gap-2">
            <a target="_blank" href="https://www.facebook.com/provenvalor"><img src="/img/social-fb.svg" alt="Proven Valor on Facebook" /></a>
            <a target="_blank" href="https://www.instagram.com/provenvalor"><img src="/img/social-ig.svg" alt="Proven Valor on Instagram" /></a>
            <a target="_blank" href="https://x.com/provenvalor"><img src="/img/social-x.svg" alt="Proven Valor on X" /></a>
          </div>
        </div>
        <div className="mt-6 sm:mt-4 flex flex-col sm:flex-row gap-2 justify-center text-center text-sm md:justify-end">
          <span className="whitespace-nowrap">We Accept</span>
          <div className="grid grid-cols-6 justify-items-center	items-center gap-2">
            <img className="w-8 h-auto" src="/img/payment-cc-visa.svg" alt="Visa" />
            <img className="w-8 h-auto" src="/img/payment-cc-amex.svg" alt="Amex" />
            <img className="w-8 h-auto" src="/img/payment-cc-mastercard.svg" alt="MasterCard" />
            <img className="w-8 h-auto" src="/img/payment-cc-jcb.svg" alt="JCB" />
            <img className="w-8 h-auto" src="/img/payment-cc-unionpay.svg" alt="UnionPay" />
            <img className="w-8 h-auto" src="/img/payment-cc-discover.svg" alt="Discover" />
            <img className="w-8 h-auto col-start-2 sm:col-start-1" src="/img/payment-apple-pay.svg" alt="Apple Pay" />
            <img className="w-9 h-auto" src="/img/payment-google-pay.svg" alt="Google Pay" />
            <img className="w-6 h-auto" src="/img/payment-cash-app.svg" alt="Cash App" />
            <img className="w-8 h-auto" src="/img/payment-amazon-pay.svg" alt="Amazon Pay" />
          </div>
        </div>
      </div>
      {/* <ThemeSwitch /> */}
    </div >
  )
}
