import { Link } from '@remix-run/react'
import { useState } from 'react'
import { Button } from '#app/components/ui/button.tsx'
import { Icon } from '#app/components/ui/icon.js'
import { Logo } from '#app/layout/Logo.tsx'

const marketingLinks = [
  { label: 'What You Get', to: '/#what-you-get' },
  { label: 'Pricing', to: '/#pricing' },
  { label: 'Login', to: '/login' },
]

export default function MarketingHeader() {
  return (
    <header className="fixed z-50 w-full bg-user-nav-light-blue py-4">
      <div className="container relative">
        <nav className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
          <Logo className="h-14 w-auto md:h-22 lg:h-24" />
          <DesktopNav />
          <MobileNav />
        </nav>
      </div>
    </header>
  )
}

function DesktopNav() {
  return (
    <div className="hidden gap-10 lg:flex lg:items-center">
      {marketingLinks.map(link => (
        <Link
          key={link.to}
          className="flex h-12 items-center text-center text-lg font-medium uppercase text-gray-900 hover:shadow-menu"
          to={link.to}
        >
          {link.label}
        </Link>
      ))}
      <Button asChild variant="default" size="lg">
        <Link to="/signup">GET STARTED</Link>
      </Button>
    </div>
  )
}

function MobileNav() {
  const [open, setOpen] = useState(false)
  return (
    <div className="flex lg:hidden">
      <Icon
        name="hamburger"
        className="h-6 w-6 cursor-pointer fill-yankees-blue font-bold hover:fill-yankees-blue/60"
        onClick={() => setOpen(!open)}
      />

      <div
        className={`absolute right-0 top-8 p-8 ${open ? 'flex animate-fade-in gap-2' : 'hidden animate-fade-out'}`}
      >
        <div className="flex w-full justify-between gap-4 rounded bg-white p-4 shadow-md ring-1 ring-black ring-opacity-5">
          <div>
            {marketingLinks.map(link => (
              <Link
                key={link.to}
                className="block rounded-md px-4 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
                to={link.to}
                onClick={() => setOpen(!open)}
              >
                {link.label}
              </Link>
            ))}
            <Link
              to="/signup"
              className="block rounded-md px-4 py-2 text-base font-medium text-gray-900 hover:bg-gray-50"
              onClick={() => setOpen(!open)}
            >
              Get Started
            </Link>
          </div>
          <div className="flex">
            <div>
              <div className="rounded-md p-2 hover:bg-gray-50">
                <Icon
                  size="sm"
                  name="cross-1"
                  className="h-6 w-6 cursor-pointer font-bold hover:bg-gray-50"
                  onClick={() => setOpen(!open)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
