import { Link, useMatches } from '@remix-run/react'
import { useEffect } from 'react'
import { Icon } from '#app/components/ui/icon.js'
import { Logo } from '#app/layout/Logo'
import { ROLE_ADMIN } from '#app/utils/constants.js'
import { defaultRedirectTo, useUser } from '#app/utils/user.js'

const logoutPath = '/logout'

// TODO add tests
const adminLinks = [
	{ name: 'Dashboard', to: '/admin/dashboard', icon: 'dashboard' },
	{ name: 'Members', to: '/admin/members', icon: 'user' },
	{ name: 'Discounts', to: '/admin/discount', icon: 'tags' },
	{ name: 'Export', to: '/admin/card-batches', icon: 'csv' }, // set default limit 1000
	{ name: 'Log out', to: logoutPath, icon: 'exit' },
] as const

const userNavigationLinks = [
	{ name: 'Home', to: '/member', icon: 'user' },
	// { name: 'Benefits', to: '/member/benefits', icon: 'chart-line' },  // Removed on 08-20-2024 since feature is not ready
	{ name: 'Discounts', to: '/member/discounts', icon: 'tags' },
	{ name: 'DigitalDuffle™', to: '/member/documents', icon: 'file' },
	{ name: 'CommandCard™', to: '/member/card', icon: 'id-card' },
	{ name: 'Billing', to: '/member/billing', icon: 'credit-card' },
	{ name: 'Settings', to: '/member/settings', icon: 'gear' },
	{ name: 'Support', to: '/member/support', icon: 'phone' },
	{ name: 'Log out', to: logoutPath, icon: 'exit' },
] as const

export default function UserSidebar({
	open,
	setOpen,
}: {
	open: boolean
	setOpen: (open: boolean) => void
}) {
	const pathname =
		typeof document !== 'undefined' && typeof window !== 'undefined'
			? window?.location?.pathname
			: ''

	// If a link is clicked in a small or xs viewport, close the sidebar after the page changes
	useEffect(() => {
		if (open) {
			setOpen(false)
		}
	}, [pathname])

	return (
		<>
			<div className="sticky top-0 z-10 bg-user-nav-light-blue p-4 sm:hidden">
				<div className="flex">
					<Icon
						name="hamburger"
						className="h-6 w-6 cursor-pointer fill-yankees-blue font-bold"
						onClick={() => setOpen(!open)}
					/>
					<div className="flex flex-1 justify-center">
						<Logo className="h-[64px]" to="/member" />
					</div>
					<div className="w-6" /> {/* Placeholder to balance the layout and center the logo */}
				</div>
			</div>
			<Nav
				className="h-dvh w-full max-w-full animate-fade-in sm:hidden"
				includeLogo={false}
				open={open}
			/>
			<Nav
				className="hidden sm:fixed sm:block sm:min-h-full sm:w-[259px] sm:max-w-[259px]"
				includeLogo={true}
				open={true}
			/>
		</>
	)
}

function Nav({
	className,
	includeLogo,
	open,
}: {
	className: string
	includeLogo: boolean
	open: boolean
}) {
	const user = useUser()

	if (!open) {
		return null
	}

	const isAdmin = user.roles.some(role => role.name === ROLE_ADMIN)

	return (
		<nav className={`z-2 bg-user-nav-light-blue print:hidden ${className}`}>
			{includeLogo ? (
				<div className="mx-4 mb-8 flex justify-center px-2 pt-8">
					<Logo className="h-[90px]" to={defaultRedirectTo(isAdmin)} />
				</div>
			) : null}

			<div>
				{(isAdmin ? adminLinks : userNavigationLinks).map(link => (
					<SidebarLink key={link.name} link={link} />
				))}
			</div>
		</nav>
	)
}

function SidebarLink({
	link,
}: {
	link: (typeof userNavigationLinks | typeof adminLinks)[number]
}) {
	const linkCommonClassName = `align-center m-2 flex flex-1 gap-3 rounded-xl px-4 py-2 text-lg ${activeLinkStyles({ to: link.to })}`

	if (link.to === logoutPath) {
		return (
			<form
				method="POST"
				action={link.to}
				className="mb-1 flex flex-1 justify-center px-4 hover:bg-cadet-blue"
			>
				<button
					type="submit"
					className={linkCommonClassName}
				>
					<Icon name={link.icon} />
					<span className="font-bold">{link.name}</span>
				</button>
			</form>
		)
	}

	return (
		<div className="mb-1 flex flex-1 justify-center px-4 hover:bg-cadet-blue">
			<Link
				to={link.to}
				aria-current={isActiveLink({ to: link.to }) ? 'page' : undefined}
				className={linkCommonClassName}
				target="_self"
			>
				<Icon name={link.icon} className={activeSVGStyles({ to: link.to })} />
				<span className="font-bold">{link.name}</span>
			</Link>
		</div>
	)
}

/**
 * Checks if the `matches` returned from `useMatches` has a pathname that matches the link's `to` prop.
 * @returns boolean
 */
function isActiveLink({ to }: { to: string }) {
	const matches = useMatches()
	const lastMatchPathName = matches?.[matches.length - 1]?.pathname

	if (!lastMatchPathName) {
		return false
	}

	// Exception code, sort of hacky, but /member was matching all logged-in user routes
	if (to === '/member') {
		return lastMatchPathName === to
	}

	return lastMatchPathName.startsWith(to)
}

function activeSVGStyles({ to }: { to: string }) {
	const isActive = isActiveLink({ to })
	return isActive ? 'fill-white' : 'fill-yankees-blue'
}

function activeLinkStyles({ to }: { to: string }) {
	const isActive = isActiveLink({ to })
	return isActive ? 'bg-primary text-white' : 'text-yankees-blue'
}
