import { Outlet } from '@remix-run/react'
import MarketingFooter from '#app/layout/marketing/MarketingFooter'
import MarketingHeader from '#app/layout/marketing/MarketingHeader'

export default function MarketingLayout() {
	return (
		<div className="flex h-screen flex-col justify-between">
			<MarketingHeader />
			<div className="mt-8 flex-1 sm:mt-20 md:mt-28">
				<Outlet />
			</div>
			<MarketingFooter />
		</div>
	)
}
