import { Outlet } from '@remix-run/react'
import { useState } from 'react'
import UserSidebar from '#app/layout/user/UserSidebar'

export default function UserLayout() {
	const [open, setOpen] = useState(false)

	// Note: The UserSidebar is using `fixed` if the screensize is `sm` or larger so the body needs to have a `ml` class to push the content to the right

	return (
		<div className="sm:flex-start h-dvh sm:flex sm:flex-1 sm:flex-row">
			<UserSidebar open={open} setOpen={setOpen} />
			<div className={`w-full sm:ml-64 print:ml-0 ${open ? 'hidden' : ''}`}>
				<div className="container mb-32 mt-8 sm:my-8">
					<Outlet />
				</div>
			</div>
		</div>
	)
}
